import React from "react";
import "./Footer.css"

const Footer=()=>{
    return(
        <div id="Footer">
            Created with Material UI and CoinMarketCap API
        </div>
    )
}

export default Footer